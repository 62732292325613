









import Vue from 'vue'

export default Vue.extend({
    name: 'MoneyRow',
    computed: {
        amount: function() {
            let text = this.$slots?.default[0]?.text as string;
            if (text.indexOf('.') === -1 && text.indexOf(',') === -1) {
                text += ',00';
            }
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
        }
    },
    methods: {
    }
})
