





























































































import Vue from 'vue'

export default Vue.extend({
    name: 'AppBar',

    data: () => ({
    }),
    computed: {
        offer() {
            return this.$store.state.offers[this.$store.state.currentOfferIndex];
        },
    },
    methods: {
        printMe() {
            window.print();
        },
        scrollTo: function (htmlElementId:any) {
            this.$vuetify.goTo('#' + htmlElementId, {duration: 300, offset: 0, easing: 'easeInOutCubic'});
        },
    }
})
