



































































import Vue from 'vue'
import Offer from '../components/Offer.vue';



export default Vue.extend({
  name: 'Home',

  components: {Offer},
  data: () => ({
  }),
  methods: {
    toOffer(id) {
      this.$router.push('/offers/' + id);
    },
  },
  computed: {
    offers: function() {
      return this.$store.state.offers;
    },
    state: function() {
      return this.$store.state;
    },
    /*offerCount: function() {
      return this.$store.state.offers.length;
    }*/
  },
  mounted:  function() {
    if (this.$store.state.loading === 'init') {
      console.log('Dispatching loadCustomer');
      this.$store.dispatch('loadCustomer', {customerId: this.$route.params.customerId, token: this.$route.params.token});
    } else {
      console.log('No dispatch today');
    }
  },
  watch: {
    /*offerCount(newValue, oldValue) {
      if (newValue == 1) {
        this.$router.push('/offers/' + this.$store.state.offers[0].id);
      }
    }*/
  }
})
