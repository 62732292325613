import Vue from 'vue'
import Vuex from 'vuex'
import {customerWithOffers} from '../services/ZonwattApi';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    customer: {},
    offers: [],
    currentOfferIndex: null,
    loading: 'init',
    mode: 'main',
  },
  mutations: {
    SET_LOADING_STATE(state, payload:any) {
      const value:string = payload.value;
      state.loading = value;
    },
    SET_OFFERS(state, payload:any) {
      state.offers = payload;
      console.log('offerr', payload);
    },
    SET_CURRENT_OFFER_INDEX(state, payload:any) {
      state.currentOfferIndex = payload.index;
    },
    SET_MODE(state, payload:any) {
      state.mode = payload;
    }
  },
  actions: {
    loadCustomer(context, payload) {
      console.log('Trying to load customer');
      context.commit('SET_LOADING_STATE', {value: 'loading'});
      customerWithOffers(payload.customerId, payload.token)
          .then((data) => {
            if (typeof data.name != 'undefined') {
              //We got something valid back
              //context.commit('SET_CUSTOMER', {name: data.name});
              context.commit('SET_OFFERS', data.offers);
              if (data.offers.length == 1) {
                context.dispatch('showOffer', 0);
              }
              context.commit('SET_LOADING_STATE', {value: 'done'});
            }
          })
          .catch(error => {
            alert('Uw offerte kon niet worden geladen. Zou u contact met ons op willen nemen, zodat wij dit kunnen onderzoeken? Onze excuses voor het ongemak.')

          });
    },
    showOffer(context, index) {
      context.commit('SET_CURRENT_OFFER_INDEX', {index});
      context.commit('SET_MODE', 'offer');
      document.title = context.state.offers[index].customer.firstName + ' ' + context.state.offers[index].customer.lastName + ' - mijnZonwatt';
    },
    setMode(context, mode) {
      context.commit('SET_MODE', mode);
    }
  },
  modules: {
  }
})
