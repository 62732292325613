









import Vue from 'vue';
import AppBar from './components/AppBar.vue';
import * as easings from 'vuetify/lib/services/goto/easing-patterns'


export default Vue.extend({
  name: 'App',
  components: {AppBar},
  data: () => ({
    easings: Object.keys(easings),
  }),
  methods: {
    scrollTo: function (htmlElementId:any) {
      this.$vuetify.goTo('#' + htmlElementId, {duration: 300, offset: 0, easing: 'easeInOutCubic'});
    }
  }
});
