

















import Vue from 'vue'

export default Vue.extend({
    name: 'MaterialSpecification',
    props: ['material'],
    computed: {
    },
    methods: {
        specsheet: function() {
            console.log('specsheet is ', this.material.specsheet);
        }
    }
})
